import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/Section/section';
import MobileCartButton from '../components/Blocks/MobileCartButton/mobileCartButton';
import { AuthContext, Context } from '../Utils/Store/store';
import CategoriesStrip from '../components/CategoriesStrip/categoriesStrip';
import Carousel from '../components/Carousel/carousel';
import CookiesBanner from '../components/CookiesBanner/cookiesBanner';
import strapiAPI from '../Utils/api/strapiApi';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { captureException } from '@sentry/minimal';

const IndexPage = ({ location }) => {
  const { state } = useContext(Context);
  const cartIsNotEmpty = state.cart.length > 0;
  const [showHeaderCategories, setShowHeaderCategories] = useState(null);
  const [bannersData, setBannerData] = useState(null);
  const userIsAuthd = Cookies.getJSON('user_is_authd');

  const data = useStaticQuery(graphql`{
  allStrapiMainPages(
    sort: {fields: Order, order: ASC}
    ) {
    nodes {
      id
      Name
      URL
      Order
      products {
        IsSpicy
        LongDescription
        Name
        Price
        OldPrice
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
        }
        SmallDescription
        Options {
          Name
          Price
          iikoID
          id
          Modificators {
            Name
            Price
            iikoModifID
          }
        }
        Status {
          Status
          id
        }
        GroupOfMods {
          id
          Mod {
            Name
            Price
            id
            iikoModifID
          }
        }
        Weight
        id
        iikoID
        isFixed
        mainingredients {
          Name
          id
        }
        subcategory {
          Name
          id
        }
      }
    }
  }

  allStrapiBanners(sort: {fields: Order, order: ASC}) {
    nodes {
      id
      Description
      Heading
      textBackgroundHex
      Link
      needDarkTextColor
      Image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 80)
          }
        }
      }
    }
  }
  }
`);


  // allStrapiWelcomeSets {
  //   nodes {
  //     Name
  //     id
  //     products {
  //       IsSpicy
  //       LongDescription
  //       Name
  //       Price
  //       OldPrice
  //       Image {
  //         localFile {
  //           childImageSharp {
  //             gatsbyImageData(width: 300)
  //           }
  //         }
  //       }
  //       SmallDescription
  //       Options {
  //         Name
  //         Price
  //         iikoID
  //         id
  //         Modificators {
  //           Name
  //           Price
  //           iikoModifID
  //         }
  //       }
  //       Status {
  //         Status
  //         id
  //       }
  //       GroupOfMods {
  //         id
  //         Mod {
  //           Name
  //           Price
  //           id
  //           iikoModifID
  //         }
  //       }
  //       Weight
  //       id
  //       iikoID
  //       isFixed
  //       mainingredients {
  //         Name
  //         id
  //       }
  //       subcategory {
  //         Name
  //         id
  //       }
  //     }
  //   }
  // }


  const userIsNew = Cookies.getJSON('user_is_new');


  return (
    <div className="indes">
      <Layout location={location} headerWithNoCategories showCategories={showHeaderCategories}>
        <SEO title="Главная" />
        <Carousel arr={data.allStrapiBanners.nodes} />
        <CategoriesStrip setShowHeaderCategories={setShowHeaderCategories} />
        {/* {(userIsNew === true || userIsNew === undefined || userIsNew === null)
          && data.allStrapiWelcomeSets.nodes
            .map((val) => <Section key={`main-${val.Name}-${val.id}`} heading={val.Name} products={val.products.filter((prod) => prod.Status && prod.Status.Status !== 'hide')} />)} */}
        {data.allStrapiMainPages.nodes
          .map((val) => <Section link={val.URL} key={`main-${val.Name}-${val.id}`} heading={val.Name} products={val.products.filter((prod) => prod.Status && prod.Status.Status !== 'hide')} />)}
        {cartIsNotEmpty && <MobileCartButton />}

        {/* <CookiesBanner /> */}
      </Layout>
    </div>
  );
};

export default IndexPage;
